:root {
  --ion-background-color: linear-gradient(
    theme("colors.slate.800"),
    theme("colors.slate.950")
  );
  --ion-text-color: theme("colors.white");
  --ion-item-background: theme("colors.slate.950");
}

html {
  /* 
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}
